<template>
  <div class="multilevel">
    <el-table :data="tableData" stripe :height="tableHeight" style="width: 100%">
      <el-table-column type="index" width="70" label="序号"></el-table-column>
      <el-table-column prop="companyName" label="所属企业"></el-table-column>
      <el-table-column prop="driverName" label="驾驶员姓名"></el-table-column>
      <el-table-column prop="idCard" label="身份证号码"></el-table-column>

      <el-table-column prop="alarmCount" label="报警总数"></el-table-column>
      <el-table-column prop="oneLevelAlarm" label="一级报警"></el-table-column>
      <el-table-column prop="twoLevelAlarm" label="二级报警"></el-table-column>
      <el-table-column prop="speedOverAlarm" label="超速报警"></el-table-column>
      <el-table-column prop="positionRunMile" label="里程数"></el-table-column>
      <el-table-column prop="avgRunMileAlarm" label="百公里报警"></el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="pageObj.pageSize"
        :current-page="pageObj.currentPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
    },
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    total: {
      type: Number,
    },
  },
  watch: {
    height(val, oldVal) {
      setTimeout(() => {
        this.tableHeight = val;
      }, 200);
    },
  },

  data() {
    return {
      pageObj: {
        currentPage: 1,
        pageSize: 10,
      },
      tableHeight: 0,
    };
  },

  methods: {
    handleSizeChange(val) {
      this.pageObj.pageSize = val;
      this.$emit("sendInfo", this.pageObj);
    },
    handleCurrentChange(val) {
      this.pageObj.currentPage = val;
      this.$emit("sendInfo", this.pageObj);
    },
    reset() {
      this.pageObj.currentPage = 1;
      this.pageObj.pageSize = 10;
    },
  },
};
</script>

<style></style>
