<template>
  <div class="driver-score" ref="driverScore">
    <el-tabs type="border-card" @tab-click="clickTab" v-model="form.type">
      <el-tab-pane label="统计" name="1">
        <div class="form-area" ref="form">
          <analysis-form @getFormVal="onSubmit" :showCar="false">
            <el-button
              type="primary"
              size="small"
              :disabled="comArr.length === 0"
              @click="exportData(1)"
              :loading="downLoadStatus"
              >导出</el-button
            >
          </analysis-form>
        </div>
        <!-- 企业统计 -->
        <company-table
          ref="pagination1"
          :height="tableHeight"
          :total="total"
          :tableData="comArr"
        />
      </el-tab-pane>
      <el-tab-pane label="详情" name="2">
        <div class="form-area">
          <analysis-form @getFormVal="onSubmit" :showCar="false" :showDriver="true">
            <el-button
              type="primary"
              size="small"
              :disabled="carArr.length === 0"
              :loading="downLoadStatus"
              @click="exportData(2)"
              >导出</el-button
            >
          </analysis-form>
        </div>
        <!-- 车辆明细 -->
        <car-table
          ref="pagination2"
          :height="tableHeight"
          :total="total"
          :tableData="carArr"
          @sendInfo="getPageInfo"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import analysisForm from "@/components/analysisForm/analysisForm.vue";
import companyTable from "./components/companyTable.vue";
import carTable from "./components/carTable.vue";
import { queryDriverAnalysis, queryDriverOnCompanyAnalysis } from "@/api/lib/gps-api.js";

import { addExcelExport } from "@/api/lib/refreshAlarm.js";
import { getCurent, formatDate } from "@/common/utils/index";

export default {
  name: "driverScore",
  components: {
    analysisForm,
    companyTable,
    carTable,
  },
  data() {
    return {
      tableHeight: 0,
      tableData: [],
      state: 0,
      form: {
        type: "1",
        driverIds: null,
        companyId: null,
        beginTime: null,
        endTime: null,
        currentPage: 1,
        pageSize: 10,
      },
      carArr: [], //   车辆明细
      comArr: [],
      total: 0,
      exportForm: {},
      downLoadStatus: false,
    };
  },
  methods: {
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.driverScore.clientHeight;
      const formHeight = this.$refs.form.clientHeight || 64;
      this.tableHeight = wholeHeight - formHeight - 40 - 30 - 10 - 24;
    },
    //导出
    exportData(key) {
      this.downLoadStatus = true;
      delete this.exportForm.currentPage;
      delete this.exportForm.pageSize;
      let data = {
        baseUrl: "report",
        userId: sessionStorage.getItem("userId"),
        createTime: formatDate(new Date()),
        fileName:
          key === 1
            ? `驾驶员行为分析统计-${getCurent()}`
            : `驾驶员行为分析详情-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath:
          key === 1
            ? `/illegalExport/queryDriverOnCompanyAnalysis`
            : `/illegalExport/queryDriverAnalysisExport`,
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    // 提交
    onSubmit(value) {
      this.form = { ...this.form, ...value };
      this.form.currentPage = 1;
      this.getDataList();
    },

    // 获取列表
    getDataList() {
      let data = { ...this.form };
      delete data.type;
      switch (this.form.type) {
        case "2":
          /**获取车辆详情*/
          queryDriverAnalysis(data).then((res) => {
            if (res.code === 1000 && res.data.total > 0) {
              this.carArr = res.data.list;
              this.total = res.data.total;
            } else {
              this.carArr = [];
              this.total = 0;
            }
          });
          break;
        case "1":
          /**获取企业统计*/
          delete data.vehicleNos;
          delete data.driverIds;
          queryDriverOnCompanyAnalysis(data).then((res) => {
            if (res.code === 1000 && res.data) {
              this.comArr = [res.data];
              this.total = 1;
            } else {
              this.comArr = [];
              this.total = 0;
            }
          });
          break;
      }
      this.exportForm = { ...data };
    },
    /**切换统计|详情 */
    clickTab(tab, event) {
      this.total = 0;
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.comArr = [];
      this.carArr = [];
    },
    /**分页参数切换 */
    getPageInfo(params) {
      this.form.currentPage = params.currentPage;
      this.form.pageSize = params.pageSize;
      if (this.carArr.length === 0) return;

      this.getDataList();
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>

<style lang="scss" scoped>
.driver-score {
  height: 100%;
  width: 100%;
  .el-tabs {
    height: 100%;
  }
}
.theme-project-gps {
  .driver-score {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
